import React from 'react';
import { useTranslation } from 'react-i18next';
import Swipe from 'react-easy-swipe';
import debounce from 'lodash/debounce';
import { useLiveMessage } from '../context/LiveMessageProvider';
import { trackEvent } from '../utils';

/**
 * Get the next number in a range.
 * If the candidate is greater than the max, return the min.
 * If the candidate is less than the min, return the max.
 * Otherwise, return the candidate.
 * @param {number} min
 * @param {number} max
 * @param {number} candidate
 *
 * @example
 * getWithinRange(0, 10, 11); // 0
 * getWithinRange(0, 10, -1); // 10
 * getWithinRange(0, 10, 5); // 5
 */
function getWithinRange(min, max, candidate) {
  if (candidate > max) return min;
  if (candidate < min) return max;
  return candidate;
}

/**
 * @typedef {Object} SegmentDetailsProps
 * @property {React.ReactNode} children
 * @property {number} currentSegment
 * @property {typeof SegmentData} [segments=SegmentData]
 * @property {React.Dispatch<React.SetStateAction<number>>} setCurrentSegment
 */

/** @param {SegmentDetailsProps} props */
function SegmentDetails({
  children,
  currentSegment,
  filteredSegments,
  setCurrentSegment,
}) {
  const filteredSegmentLength = filteredSegments.length;

  const hasSegments = filteredSegmentLength > 1;
  const { setPoliteMessage } = useLiveMessage();
  const { t } = useTranslation('common');

  /** @param { 'back' | 'forward' } direction */
  const advanceSegment = (direction) => {
    const index = filteredSegments.findIndex((item) => item.id === currentSegment);

    const nextIndex = getWithinRange(0, filteredSegmentLength - 1, direction === 'forward' ? index + 1 : index - 1);
    const eventType = direction === 'forward' ? t('app.next_segment') : t('app.prev_segment');

    const { id: nextSegmentId, name: nextSegmentName } = filteredSegments[nextIndex];

    setCurrentSegment(nextSegmentId);
    setPoliteMessage(
      `${nextSegmentId}: ${nextSegmentName}`,
    );
    trackEvent({
      type: 'event',
      event: 'Interaction',
      event_category: 'Carousel',
      event_type: eventType,
      event_detail: nextSegmentId.toString(),
    });
  };
  const advanceSegmentDebounce = debounce(advanceSegment, 300);

  const onSwipeEnd = (position) => {
    if (position.x > 120) {
      advanceSegmentDebounce('back');
    } else if (position.x < -120) {
      advanceSegmentDebounce('forward');
    }
  };
  return (
    <Swipe
      onSwipeMove={(position) => onSwipeEnd(position)}
      className="segment-details"
      id="segment-details"
      tagName="section"
    >
      {hasSegments && (
        <div
          className="segment-details__controls segment-details__controls--left"
        >
          <button
            aria-label={(t('app.prev_segment'))}
            className="segment-details__controls__button segment-details__controls__button--left"
            type="button"
            onClick={() => advanceSegment('back')}
          >
            <span
              aria-hidden
              className="button-arrow button-arrow--left"
            />
          </button>
        </div>
      )}
      <div
        className="segment-details__section"
        id="segment-details__section"
      >
        {children}
      </div>
      {hasSegments && (
        <div
          className="segment-details__controls segment-details__controls--right"
        >
          <button
            aria-label={(t('app.next_segment'))}
            className="segment-details__controls__button segment-details__controls__button--right"
            type="button"
            onClick={() => advanceSegment('forward')}
          >
            <span
              aria-hidden
              className="button-arrow button-arrow--right"
            />
          </button>
        </div>
      )}
    </Swipe>
  );
}

export default SegmentDetails;
