import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { trackEvent } from '../utils';

function MarketingButton({ href, title, children }) {
  return (
    <a
      href={href}
      target="_self"
      rel="noopener noreferrer"
      className="primary-cta"
      onClick={() => trackEvent({
        type: 'event',
        event: 'Interaction',
        event_category: 'Conversion',
        event_type: 'Marketing Link Click',
        event_detail: title,
      })}
    >
      {children}
    </a>
  );
}

function MarketingSections() {
  const { t } = useTranslation('common');
  const location = useLocation();
  const language = location.pathname.includes('/en-ca') ? 'en' : 'fr';
  return (
    <div className="marketing__inner__sections">
      <article>
        <img
          src="/assets/img/PRIZM-HowWeHelp-1-DataDriven.jpg"
          alt={(t('app.alt_woman'))}
          loading="lazy"
        />
        <h3
          className="title title--secondary--font-size-small"
        >
          {t('app.driven_strategies')}
        </h3>
        <p>
          <Trans
            t={t}
            i18nKey="app.discover_where"
            components={[<sup />]}
          />
        </p>
        <MarketingButton
          href={t('app.how_we_help_link')}
          title={t('app.strategies_read_more')}
        >
          {t('app.strategies_read_more')}
        </MarketingButton>
      </article>
      <article>
        <img
          src="/assets/img/PRIZM-HowWeHelp-2-SegmentCustomers.jpg"
          alt={(t('app.alt_segments'))}
          loading="lazy"
        />
        <h3
          className="title title--secondary--font-size-small"
        >
          {t('app.segment_customers')}
        </h3>
        <p>
          <Trans
            t={t}
            i18nKey="app.segment_text"
            components={[<sup />, <sup />, <sup />]}
          />
        </p>
        { language === 'en' && (
          <MarketingButton
            href={t('app.prizm_link')}
            title={t('app.learn_about_segmentation_title')}
          >
            {t('app.learn_about_segmentation_title')}
          </MarketingButton>
        )}
      </article>
      <article>
        <img
          src="/assets/img/PRIZM-HowWeHelp-3-IdentifyBestMarkets.jpg"
          alt={(t('app.alt_people'))}
          loading="lazy"
        />
        <h3
          className="title title--secondary--font-size-small"
        >
          {t('app.identify')}
        </h3>
        <p>
          <Trans
            t={t}
            i18nKey="app.identify"
            components={[<sup />]}
          />
        </p>
        <ul>
          <li>{t('app.who')}</li>
          <li>{t('app.where')}</li>
          <li>{t('app.how')}</li>
          <li>{t('app.what')}</li>
        </ul>
        { language === 'en' && (
          <MarketingButton
            href={t('app.prizm_link')}
            title={t('app.get_answers_title')}
          >
            {t('app.get_answers_title')}
          </MarketingButton>
        )}
      </article>
      <article>
        <img
          src="/assets/img/PRIZM-HowWeHelp-4-ExecuteAndRefine.jpg"
          alt={(t('app.alt_icons'))}
          loading="lazy"
        />
        <h3
          className="title title--secondary--font-size-small"
        >
          {t('app.results_based_execution')}
        </h3>
        <p>
          <Trans
            t={t}
            i18nKey="app.adopt_perspective"
            components={[<sup />]}
          />
        </p>
        <ul>
          <li>
            {t('app.differentiate_services')}
          </li>
          <li>
            {t('app.increase_engagement')}
          </li>
          <li>
            {t('app.deploy_messaging')}
          </li>
          <li>
            {t('app.media_plans')}
          </li>
          <li>
            <Trans
              t={t}
              i18nKey="app.track_campaigns"
              components={[<sup />]}
            />
          </li>
        </ul>
        { language === 'en' && (
          <MarketingButton
            href={t('app.case_studies_link')}
            title={t('app.see_studies')}
          >
            {t('app.see_studies')}
          </MarketingButton>
        )}
      </article>
    </div>
  );
}

export default MarketingSections;
