import React, {
  Suspense, useState, useEffect, useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import Cookies from 'universal-cookie';
import TagManager from 'react-gtm-module';
import Modal from 'react-modal';
import { useUserIsTabbing } from './hooks';
import Header from './components/Header';
import Hero from './components/Hero';
import Search from './components/Search';
import PostalLookupInput from './components/PostalLookupInput';
import FilterStats from './components/FilterStats';
import SegmentThumbnails from './components/SegmentThumbnails';
import FilterBar from './components/FilterBar';
import SegmentDetails from './components/SegmentDetails';
import SegmentDetailsSlide from './components/SegmentDetailsSlide';
import Loader from './components/Loader';
import Marketing from './components/Marketing';
import CTA from './components/CTA';
import Footer from './components/Footer';
import Login from './components/Login';
import TOS from './components/TOS';
import Interstitial from './components/Interstitial';
import LimitModal from './components/LimitModal';
import GDPRBanner from './components/GDPRBanner';

import { useLiveMessage } from './context/LiveMessageProvider';
import i18n from './i18n';

const TRACKING_ID = 'GTM-5Q64RFF';
const TAG_MANAGER_ARGS = {
  gtmId: TRACKING_ID,
  auth: process.env.REACT_APP_GA_AUTH,
  preview: process.env.REACT_APP_GA_ENV,
};

Modal.setAppElement('#root');

const WebMapView = React.lazy(() => Promise.all([
  import('./components/WebMapView'),
  // eslint-disable-next-line no-promise-executor-return
  new Promise((resolve) => setTimeout(resolve, 5000)),
]).then(([moduleExports]) => moduleExports));

const LOOKUP_INTERSTIAL_COUNT = 5;
const LOOKUP_LIMIT = 11;

const COOKIES = new Cookies();

function Layout({ language }) {
  const { t } = useTranslation('common');
  useUserIsTabbing();

  const HAS_EMPLOYEE_COOKIE = COOKIES.get('employee') === 'true';
  const HAS_GDPR_CONSENT_COOKIE = COOKIES.get('sf-tracking-consent');
  let HAS_LIMIT_COOKIE = 'false';

  const [activePostalCode, setActivePostalCode] = useState(null);
  const [tempPostalCode, setTempPostalCode] = useState(activePostalCode);
  const [currentSegment, setCurrentSegment] = useState(1);
  const [multiSegments, setMultiSegments] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [postalLoading, setPostalLoading] = useState(false);
  const [postalCounter, setPostalCounter] = useState(0);
  const [postalPosition, setPostalPosition] = useState(null);
  const [reachedLimit, setReachedLimit] = useState(false);
  const [searchedSegment, setSearchedSegment] = useState(null);
  const [ruralSegment, setRuralSegment] = useState(null);
  const [ruralName, setRuralName] = useState(null);
  const [showLogin, setShowLogin] = useState(false);
  const [showTOS, setShowTOS] = useState(false);
  const [showInterstital, setShowInterstital] = useState(false);
  const [showLimitModal, setShowLimitModal] = useState(false);
  const [showGDPRBanner, setShowGDPRBanner] = useState(!HAS_GDPR_CONSENT_COOKIE);
  const segmentResultsRef = useRef(null);
  const resultsRef = useRef(null);
  const errorRef = useRef(null);

  const [filteredSegments, setFilteredSegments] = useState([]);
  const [segmentData, setSegmentData] = useState([]);

  const { setAssertiveMessage } = useLiveMessage();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);

    // Set powered by Esri text
    const poweredByElement = document.querySelector('.esri-attribution__powered-by');
    if (poweredByElement) {
      poweredByElement.firstChild.textContent = `${t('app.powered_by')}`;
    }
    // Add aria label to the Esri logo
    const attributeLink = document.querySelector('.esri-attribution__link');
    if (attributeLink) {
      attributeLink.setAttribute('aria-label', `${t('app.esri')}`);
    }

    // Update the lang attribute in html tag
    document.documentElement.lang = lng;
  };

  const getNextMondayMidnight = () => {
    const today = new Date();
    const daysUntilNextMonday = (1 + (7 - today.getDay())) % 7;
    const nextMonday = new Date(today);
    nextMonday.setDate(today.getDate() + daysUntilNextMonday);
    nextMonday.setHours(0, 0, 0, 0); // Set time to midnight
    return nextMonday;
  };

  useEffect(() => {
    // Change the language of the page
    changeLanguage(language);

    const getSegmentData = async () => {
      let module = null;
      if (language === 'fr') {
        module = await import('./data/segments/fr-ca.json');
      } else {
        module = await import('./data/segments/en-ca.json');
      }
      const theData = module.default;
      return theData;
    };
    getSegmentData().then((data) => {
      setSegmentData(data);
      setFilteredSegments(data);
    });
  }, [language]);

  useEffect(() => {
    // Initialize GTM
    TagManager.initialize(TAG_MANAGER_ARGS);

    // Check IP and limit count
    const lookupCountWeekly = COOKIES.get('lookupCountWeekly');
    if (lookupCountWeekly) {
      fetch('https://api.ipify.org?format=json')
        .then((response) => response.json())
        .then((data) => {
          const { ip } = data;
          const lookupCountArray = lookupCountWeekly;
          const lookipIPObject = lookupCountArray.find((item) => item.ip === ip);
          if (lookipIPObject) {
            const counter = lookipIPObject.count;
            if (counter <= LOOKUP_LIMIT) {
              COOKIES.remove('dailyLimit');
            }
          }
        });
    } else {
      COOKIES.remove('dailyLimit');
    }
  }, []);

  useEffect(() => {
    // If the user has the employee cookie we don't need to show the interstitial or limit them
    if (HAS_EMPLOYEE_COOKIE) {
      return;
    }

    // If the user has the limit cookie show the limit modal
    if (HAS_LIMIT_COOKIE === 'true') {
      setReachedLimit(true);
      return;
    }

    // Show the interstitial modal on the 5th lookup only
    if (postalCounter === LOOKUP_INTERSTIAL_COUNT) {
      setShowInterstital(true);
    }

    // Show the limit popup after 10 postal code lookups within a 24 hour period.
    if (postalCounter === LOOKUP_LIMIT) {
      // Set the cookie to expire in start of each week
      const date = getNextMondayMidnight();
      COOKIES.set('dailyLimit', 'true', { expires: date });
      HAS_LIMIT_COOKIE = 'true';
      // Show the limit modal
      setReachedLimit(true);
      setShowLimitModal(true);
    }
  }, [postalCounter]);

  const checkLookupCountLimit = async () => {
    await fetch('https://api.ipify.org?format=json')
      .then((response) => response.json())
      .then((data) => {
        const { ip } = data;
        const existingCookie = COOKIES.get('lookupCountWeekly');
        const nextMonday = getNextMondayMidnight();
        let ipCountArray = [];
        if (existingCookie) {
          ipCountArray = existingCookie;
          const existingIPObject = ipCountArray.find((item) => item.ip === ip);
          if (existingIPObject) {
            if (existingIPObject.count === (LOOKUP_LIMIT - 1)) {
              COOKIES.set('dailyLimit', 'true', { expires: nextMonday });
              HAS_LIMIT_COOKIE = 'true';
              setReachedLimit(true);
              setShowLimitModal(true);
            } else {
              existingIPObject.count += 1;
            }
          } else {
            ipCountArray.push({ ip, count: 1 });
          }
          COOKIES.set('lookupCountWeekly', JSON.stringify(ipCountArray), { path: '/', expires: nextMonday });
        } else {
          ipCountArray.push({ ip, count: 1 });
          COOKIES.set('lookupCountWeekly', JSON.stringify(ipCountArray), { path: '/', expires: nextMonday });
        }
      });
  };

  const getPostalData = () => {
    const postalCode = activePostalCode;

    // This will be the first render, so don't do anything
    if (postalCode === null) {
      return;
    }

    if (postalCode === undefined) {
      setErrorMessage(t('app.enter_postal'));
      setAssertiveMessage(t('app.enter_postal'));
      return;
    }

    TagManager.dataLayer({
      dataLayer: {
        type: 'event',
        event: 'Interaction',
        event_category: 'Lookup',
        event_type: `Postal Code Lookup : ${postalPosition}`,
        event_detail: activePostalCode,
      },
    });

    setPostalLoading(true);

    // Get the postal code segments
    axios.get(`${process.env.REACT_APP_API_BASE}/api/pcode/get_segment?postal_code=${postalCode.replace(' ', '')}`).then((response) => {
      const status = response.data.result;
      if (status === 'success') {
        const { format } = response.data;
        const { data } = response.data;
        if (format === 'unique') {
          if (segmentResultsRef.current) {
            setTimeout(() => {
              segmentResultsRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
              });
            }, 100);
          }
          checkLookupCountLimit();
          setCurrentSegment(data);
          setPostalLoading(false);
          setPostalCounter(postalCounter + 1);
          setErrorMessage(null);
          setSearchedSegment(data);
        } else if (format === 'multi') {
          if (resultsRef.current) {
            setTimeout(() => {
              resultsRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
              });
            }, 100);
          }
          setPostalLoading(false);
          setMultiSegments(data);
          setErrorMessage(null);
        }
      }
      if (status !== 'error') {
        document.getElementById('filter-stats-section').focus();
      }
      if (status === 'error') {
        // Handle errors
        const { format } = response.data;
        if (format === 'invalid_pcode') {
          setErrorMessage('app.invalid_pcode');
          setAssertiveMessage('app.invalid_pcode');
        } else if (format === 'non_residential_zoning') {
          setErrorMessage('app.non_residential_zoning');
          setAssertiveMessage('app.non_residential_zoning');
        }
        setPostalLoading(false);
      }
    });
  };

  const setMultiSegmentData = (id, name) => {
    setMultiSegments([]);
    setRuralSegment(id);
    setRuralName(name);
    setCurrentSegment(id);
  };

  useEffect(() => {
    // When the postal code changes get the data for it
    getPostalData();
  }, [activePostalCode]);

  useEffect(() => {
    // When the segments change update current segment
    if (filteredSegments.length) {
      setCurrentSegment(filteredSegments[0].id);
    }
  }, [filteredSegments]);

  const updatePostalCode = (postalCode) => {
    if (HAS_EMPLOYEE_COOKIE) {
      setActivePostalCode(postalCode);
      return;
    }

    if (reachedLimit && HAS_LIMIT_COOKIE) {
      setShowLimitModal(true);
    } else {
      setActivePostalCode(postalCode);
    }
  };

  const triggerErrorAlert = () => {
    // If there is an error message, re-trigger it for screen readers
    if (errorMessage) {
      errorRef.current.setAttribute('role', 'none');

      setTimeout(() => {
        errorRef.current.setAttribute('role', 'alert');
      }, 10);
    }
  };

  return (
    <>
      {showGDPRBanner && <GDPRBanner setShowGDPRBanner={setShowGDPRBanner} />}
      <a
        className="skip-to-main"
        href="#main"
      >
        Skip to main content
      </a>
      <Header language={language} />
      <main>
        <Hero
          errorMessage={errorMessage}
          postalLoading={postalLoading}
          errorRef={errorRef}
        >
          <PostalLookupInput
            setActivePostalCode={updatePostalCode}
            activePostalCode={activePostalCode}
            postalLoading={postalLoading}
            setTempPostalCode={setTempPostalCode}
            tempPostalCode={tempPostalCode}
            setPostalPosition={() => setPostalPosition('Hero Lookup')}
            setInputFieldLabelID="hero"
            triggerErrorAlert={triggerErrorAlert}
          />
        </Hero>
        <section
          id="explore"
          ref={resultsRef}
        >
          <Search
            errorMessage={errorMessage}
            postalLoading={postalLoading}
          >
            <PostalLookupInput
              setActivePostalCode={updatePostalCode}
              activePostalCode={activePostalCode}
              postalLoading={postalLoading}
              multiSegments={multiSegments}
              setMultiSegmentData={setMultiSegmentData}
              setTempPostalCode={setTempPostalCode}
              tempPostalCode={tempPostalCode}
              setPostalPosition={() => setPostalPosition('Main Lookup')}
              setInputFieldLabelID="search"
              triggerErrorAlert={triggerErrorAlert}
            />
          </Search>
          <div
            id="segment-results"
            ref={segmentResultsRef}
          >
            <FilterStats
              segments={filteredSegments}
            >
              <FilterBar
                segments={segmentData}
                filteredSegments={filteredSegments}
                setFilteredSegments={setFilteredSegments}
                activePostalCode={activePostalCode}
                language={language}
              />
            </FilterStats>
          </div>
          <div
            className={!filteredSegments.length ? 'util__disabled' : undefined}
          >
            <SegmentThumbnails
              segments={filteredSegments}
              currentSegment={currentSegment}
              setCurrentSegment={setCurrentSegment}
              setDisabledStatus={!filteredSegments.length ? true : undefined}
            />
            <Suspense
              fallback={(
                <div className="map__container map__container--loading">
                  <Loader />
                </div>
        )}
            >
              <WebMapView
                activePostalCode={activePostalCode}
                currentSegment={currentSegment}
              />
            </Suspense>
            <SegmentDetails
              filteredSegments={filteredSegments}
              currentSegment={currentSegment}
              setCurrentSegment={setCurrentSegment}
              language={language}
            >
              <SegmentDetailsSlide
                segments={filteredSegments.length ? filteredSegments : segmentData}
                currentSegment={currentSegment}
                currentPostalCode={activePostalCode}
                searchedSegment={searchedSegment}
                ruralName={ruralName}
                ruralSegment={ruralSegment}
              />
            </SegmentDetails>
          </div>
        </section>
        <CTA type="learn" />
        <Marketing />
        <CTA type="questions" />
        <Modal
          contentLabel="Login"
          isOpen={showLogin}
          onRequestClose={() => setShowLogin(false)}
          bodyOpenClassName="util__body-lock"
          className="modal__container"
        >
          <Login
            close={() => setShowLogin(false)}
          />
        </Modal>
        <Modal
          contentLabel="Terms of Service"
          isOpen={showTOS}
          onRequestClose={() => setShowTOS(false)}
          bodyOpenClassName="util__body-lock"
          className="modal__container"
        >
          <TOS close={() => setShowTOS(false)} />
        </Modal>
        <Modal
          contentLabel="Super Charge Your Campaigns"
          isOpen={showInterstital}
          onRequestClose={() => setShowInterstital(false)}
          bodyOpenClassName="util__body-lock"
          className="modal__container"
        >
          <Interstitial close={() => setShowInterstital(false)} />
        </Modal>
        <Modal
          contentLabel="Access All Of The Data"
          isOpen={showLimitModal}
          onRequestClose={() => setShowLimitModal(false)}
          bodyOpenClassName="util__body-lock"
          className="modal__container"
        >
          <LimitModal close={() => setShowLimitModal(false)} />
        </Modal>
      </main>
      <Footer
        showLogin={setShowLogin}
        showTOS={setShowTOS}
      />
    </>
  );
}

export default Layout;
