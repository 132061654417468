import React from 'react';
import { useTranslation, Trans } from 'react-i18next';

function LimitModal({ close }) {
  const { t } = useTranslation('common');
  return (
    <div
      className="modal__inner modal__inner--limit"
    >
      <h3
        id="dialog-title"
        className="title title--secondary"
      >
        {t('app.all_data')}
      </h3>
      <p
        id="dialog-description"
      >
        <Trans
          t={t}
          i18nKey="app.promo_tool_restriction"
          components={[<sup />]}
        />
      </p>
      <a
        className="primary-cta"
        href={t('app.contact_link')}
      >
        {t('app.get_in_touch')}
      </a>
      <button
        className="modal__inner__close"
        aria-label={t('app.closeUpper')}
        onClick={() => close()}
        type="button"
      >
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="2.22192" y="16.1421" width="20" height="2" transform="rotate(-45 2.22192 16.1421)" fill="black" />
          <rect x="3.63599" y="2" width="20" height="2" transform="rotate(45 3.63599 2)" fill="black" />
        </svg>
      </button>
    </div>
  );
}

export default LimitModal;
