/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import enFrIcon from '../icons/en-fr.svg';

export default function LanguageSelector({ lang }) {
  const [isOpen, setIsOpen] = useState(false); // State to manage the dropdown open/close state
  const [language, setLanguage] = useState(lang); // State to manage the selected language
  const navigate = useNavigate(); // Hook to programmatically navigate
  const changeLanguage = (theTargetId) => {
    setLanguage(theTargetId);
    navigate(`/${theTargetId}-ca`);
  };

  useEffect(() => {
    const closeDropdown = (event) => {
      if (!event.target.closest('.language-selector')) {
        setIsOpen(false);
      }
    };
    document.addEventListener('click', closeDropdown);
    return () => document.removeEventListener('click', closeDropdown);
  }, []);

  const handleSelectClick = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div
      className="dropdown language-selector language-toggle"
    >
      <button
        className="btn btn-default dropdown-toggle"
        type="button"
        id="languageSelector"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded={isOpen ? 'true' : 'false'}
        onClick={() => handleSelectClick()}
      >
        <span className="util__screen-reader-only">Button to change language. Currently set to
          {language === 'en' ? ' English. ' : ' French. '}
        </span>
        <img
          src={enFrIcon}
          alt="Canadian Flag"
        />
        {language === 'en' ? 'EN' : 'FR'}
        <i className="select-down-arrow" aria-hidden="true">&#8964;</i>
      </button>
      <ul
        className={`dropdown-menu lang-selector ${isOpen ? ' block' : ''}`}
        aria-labelledby="languageSelector"
      >
        <li>
          <button
            type="button"
            id="en"
            tabIndex="0"
            onClick={() => changeLanguage('en')}
          >
            <img src={enFrIcon} alt="Canadian flag. Click to change language to English. " />
            <span lang="en">English (Canada)</span>
          </button>
        </li>
        <li>
          <button
            type="button"
            id="fr"
            tabIndex="0"
            onClick={() => changeLanguage('fr')}
          >
            <img src={enFrIcon} alt="Canadian flag. Click to change language to French. " />
            <span lang="fr">Français (Canada)</span>
          </button>
        </li>
      </ul>
      <div aria-live="polite" className="util__screen-reader-only">
        {language === 'en' ? 'Language changed to English' : 'Language changed to French'}
      </div>
    </div>
  );
}
