import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { trackEvent } from '../utils';

function Footer({ showLogin, showTOS }) {
  const { t } = useTranslation('common');
  const handleLogin = () => {
    showLogin(true);
    trackEvent({
      type: 'event',
      event: 'Interaction',
      event_category: 'User Interaction',
      event_type: 'Click',
      event_detail: 'Show Login',
    });
  };

  const handleTOS = () => {
    showTOS(true);
    trackEvent({
      type: 'event',
      event: 'Interaction',
      event_category: 'User Interaction',
      event_type: 'Click',
      event_detail: 'Show TOS',
    });
  };

  return (
    <footer className="site-footer">
      <a
        href="/"
        className="site-footer__logo"
      >
        <img
          src="/assets/img/prizm_logo.svg"
          alt={(t('app.prizm'))}
        />
      </a>
      <nav aria-label="Footer Navigation">
        <ul>
          <li>
            <button
              onClick={() => handleLogin()}
              type="button"
            >
              {t('app.login')}
            </button>
          </li>
          <li>
            <button
              onClick={() => handleTOS()}
              type="button"
            >
              {t('app.tos')}
            </button>
          </li>
          <li key={t('app.privacy_policy_link')}>
            <a
              href={t('app.privacy_policy_link')}
              onClick={(event) => trackEvent({
                type: 'event',
                event: 'Interaction',
                event_category: 'Navigation',
                event_type: 'Nav Click',
                event_detail: event.target.innerHTML,
              })}
            >
              {t('app.privacy')}
            </a>
          </li>
          <li key={t('app.acc_link')}>
            <a
              href={t('app.acc_link')}
              onClick={(event) => trackEvent({
                type: 'event',
                event: 'Interaction',
                event_category: 'Navigation',
                event_type: 'Nav Click',
                event_detail: event.target.innerHTML,
              })}
            >
              {t('app.accessibility')}
            </a>
          </li>
          <li key={t('app.home_link')}>
            <a
              href={t('app.home_link')}
              onClick={(event) => trackEvent({
                type: 'event',
                event: 'Interaction',
                event_category: 'Navigation',
                event_type: 'Nav Click',
                event_detail: event.target.innerHTML,
              })}
            >
              {t('app.ea')}
            </a>
          </li>
        </ul>
      </nav>
      <div className="site-footer__legal">
        <p>
          <Trans
            t={t}
            i18nKey="app.prizm_tm"
            components={[<sup />]}
          />
        </p>
      </div>
    </footer>
  );
}

export default Footer;
