import React from 'react';
import { useTranslation, Trans } from 'react-i18next';

function Search({ children, errorMessage, postalLoading }) {
  const { t } = useTranslation('common');
  return (
    <section
      className="search"
    >
      <div
        className="search__inner"
      >
        <div>
          <h2
            className="title title--secondary"
          >
            {t('app.neighborhoods')}
          </h2>
          <p
            className="title title--subtitle"
          >
            <Trans
              t={t}
              i18nKey="app.enter_code_see_sample"
              components={[<sup />]}
            />
          </p>
        </div>
        {children}
        {!postalLoading && errorMessage && (
          <p
            className="search__error"
          >
            {t(errorMessage)}
          </p>
        )}
      </div>
    </section>
  );
}

export default Search;
