import React from 'react';
import { useTranslation } from 'react-i18next';
import PostalLookupInputField from './PostalLookupInputField';
import Loader from './Loader';

function PostalLookupInput({
  setActivePostalCode,
  activePostalCode,
  postalLoading,
  multiSegments,
  setMultiSegmentData,
  tempPostalCode,
  setTempPostalCode,
  setPostalPosition,
  setInputFieldLabelID,
  triggerErrorAlert,
}) {
  const { t } = useTranslation('common');
  const handleForm = (event) => {
    event.preventDefault();
    setActivePostalCode(tempPostalCode);
    setPostalPosition();
    triggerErrorAlert();
  };

  return (
    <div className="postal-lookup">
      <form
        className="postal-lookup__input"
        onSubmit={(event) => { handleForm(event); }}
      >
        <PostalLookupInputField
          setActivePostalCode={setActivePostalCode}
          tempPostalCode={tempPostalCode}
          setTempPostalCode={setTempPostalCode}
          setInputFieldLabelID={setInputFieldLabelID}
        />
        {postalLoading && <Loader />}
        <button
          className="primary-cta"
          onClick={(event) => handleForm(event)}
          type="button"
          disabled={!tempPostalCode}
          aria-label={t('app.search')}
        >
          {t('app.search_caps')}
        </button>
      </form>
      <div
        className={multiSegments?.length > 1 ? 'postal-lookup__multi' : 'postal-lookup__multi util__hidden'}
      >
        <p>
          {activePostalCode} {t('app.rural_postal')} <br />
          <br />
          {t('app.geo_point')}
        </p>
        {multiSegments && (
        <div className="postal-lookup__multi__inner">
          <ul>
            <MultiSegments
              multiSegments={multiSegments}
              setMultiSegmentData={setMultiSegmentData}
            />
          </ul>
        </div>
        )}
      </div>
    </div>
  );
}

const MultiSegments = ({ multiSegments, setMultiSegmentData }) => {
  const segments = [...multiSegments];

  return segments.map(({ prizm_id, title }) => (
    <li
      key={`multi${title}`}
    >
      <button
        onClick={() => setMultiSegmentData(prizm_id, title)}
        type="button"
      >
        {title}
      </button>
    </li>
  ));
};

export default PostalLookupInput;
