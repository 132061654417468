import React, {
  createContext, useContext, useMemo, useState,
} from 'react';

/**
 * @typedef {Object} LiveMessageContextValue
 * @property {React.Dispatch<React.SetStateAction<?string>>} setAssertiveMessage
 * @property {React.Dispatch<React.SetStateAction<?string>>} setPoliteMessage
 */

const LiveMessageContext = /** @type {React.Context<LiveMessageContextValue>} */ (createContext());

/**
 * Provides access to `setAssertiveMessage` and `setPoliteMessage` functions,
 * which allow us to post either polite or assertive messages to assistive technologies.
 * @see: https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/ARIA_Live_Regions
 * @param {Object} props
 * @param {React.ReactNode} props.children
 */
export function LiveMessageProvider({ children }) {
  const [assertiveMessage, setAssertiveMessage] = useState(null);
  const [politeMessage, setPoliteMessage] = useState(null);

  const value = useMemo(() => ({ setAssertiveMessage, setPoliteMessage }), []);
  return (
    <LiveMessageContext.Provider value={value}>
      {children}
      <div
        aria-atomic="true"
        aria-live="polite"
        aria-relevant="all"
        className="util__screen-reader-only"
        role="region"
      >
        {politeMessage}
      </div>
      <div
        aria-atomic="true"
        aria-live="assertive"
        aria-relevant="all"
        className="util__screen-reader-only"
        role="alert"
      >
        {assertiveMessage}
      </div>
    </LiveMessageContext.Provider>
  );
}

export function useLiveMessage() {
  const context = useContext(LiveMessageContext);
  if (context === undefined) {
    throw new Error(
      'useLiveMessage must be used within a LiveMessageProvider',
    );
  }
  return context;
}
