import React from 'react';
import Cookies from 'universal-cookie';
import { useTranslation, Trans } from 'react-i18next';

const COOKIES = new Cookies();

function GDPRBanner({ setShowGDPRBanner }) {
  const { t } = useTranslation('common');
  const setGDPRCookie = (accepted) => {
    if (accepted) {
      const date = new Date();
      // Cookie expires in 1 year
      date.setDate(date.getDate() + 365);
      COOKIES.set('sf-tracking-consent', 'true', { expires: date });
    }

    setShowGDPRBanner(false);
  };

  /**
   * @param {boolean} isAccepted
   */
  function handleBannerCtaClick(isAccepted) {
    setGDPRCookie(isAccepted);
    document.querySelector('.skip-to-main').focus();
  }

  return (
    <section
      aria-label={t('app.cookies_banner')}
      className="gdpr-banner"
    >
      <div
        className="gdpr-banner__message"
      >
        <p>
          <Trans
            t={t}
            i18nKey="app.cookies"
            components={[<a href={t('app.privacy_policy_link')}>{t('app.privacy')}</a>]}
          />
        </p>
      </div>
      <div
        className="gdpr-banner__buttons"
      >
        <button
          className="gdpr-banner__buttons__accept primary-cta"
          onClick={() => handleBannerCtaClick(true)}
          type="button"
        >
          {t('app.accept')}
        </button>
        <button
          className="gdpr-banner__buttons__decline"
          onClick={() => handleBannerCtaClick(false)}
          type="button"
        >
          {t('app.decline')}
        </button>
      </div>
    </section>
  );
}

export default GDPRBanner;
