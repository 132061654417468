import React from 'react';
import { useTranslation } from 'react-i18next';
import { trackEvent } from '../utils';

function CTA({ type }) {
  const { t } = useTranslation('common');

  const content = () => ({
    type,
    title: t('app.have_questions'),
    subtitle: t('app.we_can_help'),
    cta: t('app.contact_us'),
    cta_link: t('app.contact_link'),
  });

  return (
    <section
      className={`cta cta--${type}`}
    >
      <div
        className={`cta cta--${type}__inner`}
      >
        <h3
          className="title title--secondary"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: content().title }}
        />
        {content().subtitle && <p>{content().subtitle}</p>}
        {content().image && (
          <div
            className={`cta--${type}__inner__image`}
          >
            <img
              src={content().image.src}
              alt={content().image.alt}
              loading="lazy"
            />
          </div>
        )}
        <a
          href={content().cta_link}
          onClick={() => trackEvent({
            type: 'event',
            event: 'Interaction',
            event_category: 'Conversion',
            event_type: 'CTA Click',
            event_detail: content().title,
          })}
          target="_self"
          rel="noopener noreferrer"
          className="primary-cta"
        >
          {content().cta}
        </a>
      </div>
    </section>
  );
}

export default CTA;
