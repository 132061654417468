/* eslint-disable object-curly-newline */
/* eslint-disable quotes */
/* eslint-disable max-len */
import React, { useState } from "react";
import { useTranslation, Trans } from 'react-i18next';
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import TagManager from "react-gtm-module";
import Markdown from "marked-react";

function SegmentDetailsSlide({ segments, currentSegment, activePostalCode, searchedSegment, ruralSegment, ruralName }) {
  const { t } = useTranslation('common');
  const [currentSlide, setCurrentSlide] = useState(0);

  const handleClick = (event, id, index) => {
    if (event.type === 'click' || event.keyCode === 37 || event.keyCode === 38 || event.keyCode === 39 || event.keyCode === 40) {
      TagManager.dataLayer({
        dataLayer: {
          type: "event",
          event: "Interaction",
          event_category: "Carousel",
          event_type: "Change Active Tab",
          event_detail: id.toString(),
        },
      });
      setCurrentSlide(index);
    }
  };

  // Handle the slides
  let slides = [...segments];
  const currentSegmentIndex = slides.findIndex((segment) => segment.id === currentSegment);

  if (currentSegmentIndex === 0 && slides.length > 1) {
    // The first item
    const slidesTotal = slides.length;
    const lastItem = slides[slidesTotal - 1];
    slides.unshift(lastItem);
    slides = slides.slice(0, 3);
  } else if (currentSegmentIndex === slides.length - 1 && slides.length > 1) {
    // The last item
    if (slides.length >= 3) {
      slides.push(slides.shift());
      slides = slides.slice(currentSegmentIndex - 2, currentSegmentIndex + 1);
    } else if (slides.length === 2) {
      const firstItem = slides[0];
      slides.push(firstItem);
    }
  } else {
    // Everything else
    slides = slides.slice(currentSegmentIndex - 1, currentSegmentIndex + 2);
  }

  return slides.map(({ name, id, short_description, percent_of_population, percent_of_households, description, who_they_are, how_they_think, how_they_live }, index) => {
    const slideNum = Number(`${id}`.slice(-2));
    return (
      <div
      // eslint-disable-next-line react/no-array-index-key
        key={`segmentThumbnail-${name}${index}`}
        className={currentSegment === id ? "segment-details__slide segment-details__slide--active" : "segment-details__slide util__hidden"}
      >

        {searchedSegment === id && <div className="segment-details__represents">{activePostalCode} {t('app.represents')}</div>}
        {ruralSegment === id && <div className="segment-details__represents">{ruralName} {t('app.represents')}</div>}
        <div className="segment-details__title">
          <h2 className="title title--secondary">
            <div className="segment-details__number">
              {slideNum < 10 && <span aria-hidden>0</span>}
              <span>
                {slideNum}
              </span>
            </div>
            {name}
          </h2>

        </div>
        <p className="segment-details__short-description">{short_description}</p>
        <p>
          <Trans
            t={t}
            i18nKey="app.segment_percentage"
            values={{
              percent_of_population,
              percent_of_households,
            }}
            components={[
              <em />,
              <em />,
            ]}
          />
        </p>

        <Tabs defaultIndex={currentSlide}>
          <TabList aria-label="Segment Details Navigation">
            <Tab tabIndex={currentSlide === 0 ? "0" : "-1"} onClick={(event) => handleClick(event, id, 0)} onKeyUp={(event) => handleClick(event, id, 0)}>
              {t('app.who_they_are')}
            </Tab>
            <Tab tabIndex={currentSlide === 1 ? "0" : "-1"} onClick={(event) => handleClick(event, id, 1)} onKeyUp={(event) => handleClick(event, id, 1)}>
              {t('app.how_think')}
            </Tab>
            <Tab tabIndex={currentSlide === 2 ? "0" : "-1"} onClick={(event) => handleClick(event, id, 2)} onKeyUp={(event) => handleClick(event, id, 2)}>
              {t('app.how_live')}
            </Tab>
          </TabList>

          <TabPanel>
            <section className="segment-details__slide__who">
              <div className="segment-details__slide__who__text">
                <div>
                  <Markdown>{description}</Markdown>
                </div>
              </div>
              <ul>
                {who_they_are.map(({ title, value }) => (
                  <li key={`point${title}`}>
                    <div className="react-tabs__tab-item__title">{title}</div>
                    <Markdown>{value.replace(/\//g, " / ")}</Markdown>
                  </li>
                ))}
              </ul>
            </section>
          </TabPanel>
          <TabPanel>
            <section className="segment-details__slide__think">
              <ul>
                {how_they_think.map((item) => (
                  <li key={`thinkpoint${item}`}>{item}</li>
                ))}
              </ul>
            </section>
          </TabPanel>
          <TabPanel>
            <section className="segment-details__slide__live">
              <ul>
                {how_they_live.map(({ title, value, slug }) => (
                  <li key={`point${title}`}>
                    <img src={`/assets/img/icons/${slug}.svg`} alt="" />
                    <span className="point-title">{title}</span>
                    <Markdown>{value.replace(/\//g, " / ")}</Markdown>
                  </li>
                ))}
              </ul>
            </section>
          </TabPanel>
        </Tabs>
      </div>
    );
  });
}

export default SegmentDetailsSlide;
