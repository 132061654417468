import TagManager from 'react-gtm-module';

export default function trackEvent({
  type = 'event',
  event = 'Interaction',
  event_category,
  event_type,
  event_detail,
}) {
  TagManager.dataLayer({
    dataLayer: {
      type,
      event,
      event_category,
      event_type,
      event_detail,
    },
  });
}
