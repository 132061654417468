import React, {
  memo, useEffect, useState, useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { trackEvent } from '../utils';
import { useLiveMessage } from '../context/LiveMessageProvider';

const clearButtonIcon = (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12.1294" cy="11.5" r="11.5" fill="#582C83" />
    <path
      d="M6.62939 7.65222L8.28161 6L12.1294 9.84778L15.9772 6L17.6294 7.65222L13.7816 11.5L17.6294 15.3478L15.9772 17L12.1294 13.1522L8.28161 17L6.62939 15.3478L10.4772 11.5L6.62939 7.65222Z"
      fill="white"
    />
  </svg>
);

function FilterBar({
  setFilteredSegments, filteredSegments, segments, activePostalCode,
}) {
  const { t } = useTranslation('common');
  const [filterIncome, setFilterIncome] = useState('All');
  const [filterFamily, setFilterFamily] = useState('All');
  const incomeDropdown = useRef(null);
  const familyDropdown = useRef(null);
  const { setAssertiveMessage } = useLiveMessage();

  const clearFilters = () => {
    familyDropdown.current.selectedIndex = 0;
    incomeDropdown.current.selectedIndex = 0;
    setFilterFamily('All');
    setFilterIncome('All');

    trackEvent({
      type: 'event',
      event: 'Interaction',
      event_category: 'Filtering',
      event_type: 'Filter',
      event_detail: 'Clear Filters',
    });
  };

  // Added for Accessibility
  // Returns keyboard focus to select fields
  const resetFamilyFilterFocus = () => {
    setFilterFamily('All');
    familyDropdown.current.focus();
  };

  const resetIncomeFilterFocus = () => {
    setFilterIncome('All');
    incomeDropdown.current.focus();
  };

  const getFilteredIncomeSegments = () => {
    let results;
    if (filterIncome === 'All') {
      results = segments;
    } else {
      const operation = filterIncome.charAt(0);
      const filterCondition = filterIncome.substring(1);
      // Filter by less then value
      if (operation === '<') {
        results = segments.filter((el) => el.filters.income < filterCondition);
        // Filter by greater then value
      } else if (operation === '>') {
        results = segments.filter((el) => el.filters.income > filterCondition);
        // Filter by range
      } else if (operation === '~') {
        const [rangeStart, rangeEnd] = filterCondition.split('|');
        results = segments.filter((el) => el.filters.income > rangeStart
          && el.filters.income < rangeEnd);
      }
    }
    return results;
  };

  useEffect(() => {
    if (filterIncome === 'All' && filterFamily === 'All') {
      setFilteredSegments(segments);
      // Set the dropdowns to the first option
      familyDropdown.current.selectedIndex = 0;
      incomeDropdown.current.selectedIndex = 0;
    } else if (filterIncome !== 'All' && filterFamily === 'All') {
      setFilteredSegments(getFilteredIncomeSegments());
      familyDropdown.current.selectedIndex = 0;
    } else if (filterIncome === 'All' && filterFamily !== 'All') {
      const results = segments.filter((el) => el.filters.family_life === filterFamily);
      setFilteredSegments(results);
      incomeDropdown.current.selectedIndex = 0;
    } else {
      const results = getFilteredIncomeSegments().filter((el) => el.filters.family_life
        === filterFamily);
      setFilteredSegments(results);
      if (results.length === 0) {
        setAssertiveMessage(t('app.no_matching_segments'));
      }
    }
    // Track the filtering event
    trackEvent({
      type: 'event',
      event: 'Interaction',
      event_category: 'Filtering',
      event_type: 'Filter',
      event_detail: `${filterIncome} ${filterFamily}`,
    });
  }, [filterFamily, filterIncome]);

  useEffect(() => {
    // Clear the filters when the postal code changes
    clearFilters();
  }, [activePostalCode]);

  useEffect(() => {
    // Clear the filters when all filteredSegments are displayed
    if (filteredSegments.length === segments.length) {
      clearFilters();
    }
  }, [filteredSegments]);

  return (
    <div className="filter-bar">
      <div>
        <div>
          <label
            htmlFor="filterIncome"
          >
            {t('app.income')}
          </label>
          <div className="select-group">
            <select
              id="filterIncome"
              onChange={(event) => setFilterIncome(event.target.value)}
              ref={incomeDropdown}
            >
              <option value="All">{t('app.all')}</option>
              <option value="<75000">{t('app.under_75')}</option>
              <option value="~75000|100000">{t('app.75_to_100')}</option>
              <option value="~100000|125000">{t('app.100_to_125')}</option>
              <option value="~125000|150000">{t('app.125_to_150')}</option>
              <option value=">150000">{t('app.over_150')}</option>
            </select>
            <button
              className={filterIncome !== 'All' ? 'select-group__clear select-group__clear--active' : 'select-group__clear'}
              onClick={() => resetIncomeFilterFocus()}
              aria-label={t('app.remove_income_filter')}
              type="button"
            >
              {clearButtonIcon}
            </button>
          </div>
        </div>
        <div>
          <label
            htmlFor="filterFamily"
          >
            {t('app.family_life')}
          </label>
          <div className="select-group">
            <select
              id="filterFamily"
              onChange={(event) => setFilterFamily(event.target.value)}
              ref={familyDropdown}
            >
              <option value="All">{t('app.all')}</option>
              <FamilyLifeItems segments={segments} />
            </select>
            <button
              className={filterFamily !== 'All' ? 'select-group__clear select-group__clear--active' : 'select-group__clear'}
              onClick={() => resetFamilyFilterFocus()}
              aria-label={(t('app.remove_life_filter'))}
              type="button"
            >
              {clearButtonIcon}
            </button>
          </div>
        </div>
      </div>
      {!filteredSegments.length && (
      <section className="filter-bar__message">
        <p>{t('app.no_matching_segments')}</p>
        <button
          onClick={() => clearFilters()}
          type="button"
        >
          {t('app.clear_filters')}
        </button>
      </section>
      )}
    </div>
  );
}

function FamilyLifeItems({ segments }) {
  const results = [];

  segments.forEach((segment) => {
    const item = segment.filters.family_life;
    if (!results.includes(item)) {
      results.push(item);
    }
  });

  return results.map((result) => (
    <option key={result} value={result}>
      {result}
    </option>
  ));
}

export default memo(
  FilterBar,
  (prevProps, nextProps) => (
    prevProps.activePostalCode === nextProps.activePostalCode
    && JSON.stringify(prevProps.filteredSegments) === JSON.stringify(nextProps.filteredSegments)
  ),
);
