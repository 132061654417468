/* eslint-disable max-len */
import React from 'react';
import { useTranslation, Trans } from 'react-i18next';

function TOS({ close }) {
  const { t } = useTranslation('common');
  return (
    <section>
      <div
        className="modal__inner modal__inner--tos"
      >
        <h2
          className="title title--secondary"
        >
          {t('app.tos')}
        </h2>

        <p>
          <Trans
            t={t}
            i18nKey="app.tos_marketing_only"
            components={[<a href={t('app.segmentation_link')} target="_blank" rel="noopener noreferrer" className="modal__inner--tos-link">Test</a>,
            ]}
          />
        </p>
        <p>
          <Trans
            t={t}
            i18nKey="app.lookup_terms"
            components={[<a href={t('app.home_link')} target="_blank" rel="noopener noreferrer" className="modal__inner--tos-link">Test</a>,
            ]}
          />
        </p>
        <p>
          {t('app.tool_agreement')}
        </p>
        <p>
          {t('app.consent')}
        </p>
        <button
          className="modal__inner__close"
          onClick={() => close()}
          type="button"
          aria-label={t('app.close')}
        >
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="2.22192" y="16.1421" width="20" height="2" transform="rotate(-45 2.22192 16.1421)" fill="black" />
            <rect x="3.63599" y="2" width="20" height="2" transform="rotate(45 3.63599 2)" fill="black" />
          </svg>
        </button>
      </div>
    </section>
  );
}

export default TOS;
