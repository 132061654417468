import React from 'react';

function Loader({ parentName }) {
  const strokeColor = parentName === 'map__loader' ? '#ffcb40' : '#582c83';
  return (
    <div className="loader">
      <svg aria-label="loading" className="loader__circle" viewBox="25 25 50 50">
        <circle className="loader__circle__path" cx="50" cy="50" r="20" fill="none" stroke={strokeColor} strokeWidth="2" />
      </svg>
    </div>
  );
}

export default Loader;
