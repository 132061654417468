import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import Loader from './Loader';

import { useLiveMessage } from '../context/LiveMessageProvider';

function FilterStats({ segments, children }) {
  const { t } = useTranslation('common');
  const [filteredPostalCount, setFilteredPostalCount] = useState(null);
  const [numberOfHouseholds, setNumberOfHouseholds] = useState(0);
  const [filtersActive, setFiltersActive] = useState(false);
  const { setPoliteMessage } = useLiveMessage();

  // Track whether we're on the initial render.
  // This helps us to avoid setting an aria alert when the user
  // is first loading the page.
  const isInitialRender = useRef(true);

  const calculateHouseholds = () => {
    let total = 0;
    segments.forEach((segment) => {
      total += segment.households;
    });
    const totalHouseholds = total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    setNumberOfHouseholds(totalHouseholds);
  };

  // Get the total number of postal codes represented by a segment
  const getFilteredPostalCount = () => {
    setFilteredPostalCount(null);
    let queryString = '';
    segments.forEach((segment) => {
      queryString += `segment_ids[]=${segment.id}&`;
    });
    axios.get(`${process.env.REACT_APP_API_BASE}/api/segment/get_pcode_count?${queryString}`).then((response) => {
      // If no results set to the string '0'
      if (response.data.data === "Sorry, we can't find any information matching your query. Please ensure you're passing either an integer or an array of integers") {
        setFilteredPostalCount('0');
      } else {
        const formattedCount = response.data.data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        setFilteredPostalCount(formattedCount);
      }
    });
  };

  useEffect(() => {
    getFilteredPostalCount();
    calculateHouseholds();
    // If there are segments, set a polite message with the new status
    if (segments.length === 0 && !isInitialRender.current) {
      setPoliteMessage(`${segments.length} Segment Households; ${filteredPostalCount} Postal Codes`);
    }

    isInitialRender.current = false;
  }, [segments]);

  return (
    <>
      <section
        aria-label={t('app.filter_stats')}
        className="filter-stats"
        id="filter-stats-section"
        tabIndex="-1"
      >
        <ul>
          <li>
            <b>{segments.length}</b> {t('app.segments')}
          </li>
          <li>
            <b>{numberOfHouseholds}</b> {t('app.households')}
          </li>
          <li>
            <b>{filteredPostalCount || <Loader />}</b> {t('app.postal_codes')}
          </li>
        </ul>
        <button
          className={filtersActive ? 'button--secondary button--secondary--active' : 'button--secondary'}
          onClick={() => setFiltersActive(!filtersActive)}
          type="button"
          aria-controls="filter-stats__section"
          aria-expanded={filtersActive}
        >
          <i className="filter-stats__icon" /> {t('app.filter_segments')}
        </button>
      </section>
      <section
        id="filter-stats__section"
        className={!filtersActive ? 'util__hidden' : undefined}
        aria-hidden={!filtersActive}
      >
        {children}
      </section>
    </>
  );
}

export default FilterStats;
