import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import HeroMap from './HeroMap';

export default function Hero({
  children, errorMessage, postalLoading, errorRef,
}) {
  const { t } = useTranslation('common');
  return (
    <section className="hero" id="main" tabIndex="-1">
      <div className="hero__inner">
        <div className="hero__intro">
          <h1
            className="title title--main"
            tabIndex="-1"
          >
            <Trans
              t={t}
              i18nKey="app.see_canada"
              components={[<sup />]}
            />
          </h1>
          <p
            className="title title--secondary title--secondary--font-size-large"
          >
            {t('app.with_insights')}
          </p>
          <p
            className="title title--subtitle title--subtitle title--subtitle--font-size-large"
          >
            {t('app.discover')}
          </p>
          {children}
          {!postalLoading && errorMessage && (
            <p className="hero__intro__error hero__intro__error--active" ref={errorRef}>
              <span role="none">{t(errorMessage)}</span>
            </p>
          )}
        </div>
        <HeroMap />
        <a
          href="#explore"
          className="hero__scroll-to util__desktop-only"
        >
          {t('app.explore_segments')}
        </a>
      </div>
    </section>
  );
}
