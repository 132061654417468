import React from 'react';
import {
  BrowserRouter as Router, Routes, Route, Navigate, useLocation,
} from 'react-router-dom';
import i18n from './i18n';
import Layout from './Layout';

function LanguageHandler() {
  const location = useLocation();
  const language = location.pathname.includes('/fr-ca') ? 'fr' : 'en';
  i18n.changeLanguage(language);

  // Set the lang attribute in html tag
  document.documentElement.lang = language;

  return (
    <Routes>
      <Route path="/" element={<Navigate replace to={`/${language}-ca`} />} />
      <Route path="/en-ca" element={<Layout language={language} />} />
      <Route path="/fr-ca" element={<Layout language={language} />} />
    </Routes>
  );
}

function App() {
  return (
    <Router>
      <LanguageHandler />
    </Router>
  );
}

export default App;
