import React from 'react';

import { useTranslation } from 'react-i18next';

function HeroMap() {
  const { t } = useTranslation('common');
  return (
    <div className="hero__map">
      <img src="/assets/img/dot_map_with_thumbnails.png" alt={t('app.alt_map')} />
    </div>
  );
}

// Export memoized component
export default React.memo(HeroMap);
