import React, {
  useRef, useEffect, memo,
} from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';
import { trackEvent } from '../utils';
import SegmentData from '../data/result.json';

/**
   * Get the next index in a range, looping back to 0 or maxIndex
   * if the range is exceeded.
   * @param {number} index
   * @param {number} maxIndex
   * @param {boolean} forward
   */
const getNextIndex = (index, maxIndex, forward) => {
  const nextIndex = forward ? index + 1 : index - 1;
  if (nextIndex > maxIndex) return 0;
  if (nextIndex < 0) return maxIndex;
  return nextIndex;
};

function SegmentThumbnails({
  segments, currentSegment, setCurrentSegment, setDisabledStatus,
}) {
  const activeSegment = useRef(null);
  const scrollContainer = useRef(null);

  const centerSegments = () => {
    const offsetLeft = activeSegment.current.offsetLeft
      + activeSegment.current.clientWidth / 2
      - document.documentElement.clientWidth / 2;
    scrollContainer.current.scroll({ left: offsetLeft, behavior: 'smooth' });
  };

  /** @param {KeyboardEvent} event */
  const advanceSegment = (event) => {
    const { key } = event;
    // If the user did not press any arrow key, do nothing
    if (!(key === 'ArrowRight' || key === 'ArrowDown' || key === 'ArrowLeft' || key === 'ArrowUp')) return;

    const index = segments.findIndex((item) => item.id === currentSegment);
    const forward = key === 'ArrowRight' || key === 'ArrowDown';

    const nextIndex = getNextIndex(index, segments.length - 1, forward);
    const eventType = forward ? 'Next Segment' : 'Previous Segment';

    /**  @type {string} */
    const nextSegmentId = segments[nextIndex].id;

    setCurrentSegment(nextSegmentId);

    trackEvent({
      type: 'event',
      event: 'Interaction',
      event_category: 'Carousel',
      event_type: eventType,
      event_detail: nextSegmentId.toString(),
    });

    document.getElementById(`segment-${nextSegmentId}`).focus();
    event.preventDefault();
  };

  const onClickSegment = (id) => {
    trackEvent({
      type: 'event',
      event: 'Interaction',
      event_category: 'Carousel',
      event_type: 'Change Segment',
      event_detail: id.toString(),
    });
    setCurrentSegment(id);
    document.getElementById(`segment-${id}`).focus();
  };

  useEffect(() => {
    const handleResize = () => centerSegments();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  useEffect(() => {
    if (activeSegment.current) {
      centerSegments();
    }
  }, [segments, currentSegment]);

  return (
    <ScrollContainer
      className="segment-thumbnails__container"
      innerRef={scrollContainer}
    >
      <div aria-label="Segment selector" className="segment-thumbnails__nav" role="radiogroup">
        <Segments
          segments={segments.length ? segments : SegmentData}
          currentSegment={currentSegment}
          onClickSegment={onClickSegment}
          advanceSegment={advanceSegment}
          activeSegment={activeSegment}
          setDisabled={setDisabledStatus}
        />
      </div>
    </ScrollContainer>
  );
}

const Segments = ({
  segments,
  currentSegment,
  advanceSegment,
  onClickSegment,
  activeSegment, setDisabled,
}) => segments.map(({ id, name }) => (

  <button
    aria-checked={currentSegment === id}
    className={currentSegment === id ? 'segment-thumbnails__nav__item segment-thumbnails__nav__item--active' : 'segment-thumbnails__nav__item'}
    disabled={setDisabled}
    id={`segment-${id}`}
    role="radio"
    tabIndex={currentSegment === id ? 0 : -1}
    type="button"
    key={id}
    ref={currentSegment === id ? activeSegment : null}
    onClick={() => onClickSegment(id)}
    onKeyDown={(e) => advanceSegment(e)}
  >
    <div
      className="segment-thumbnails__nav__item__inner"
    >
      <img
        src={`/assets/img/segment_icons/segment_${id}.svg`}
        alt=""
        loading="lazy"
        width="180"
        height="180"
      />
      <div
        className="segment-thumbnails__nav__item__content"
      >
        <span aria-hidden>{(`0${id}`).slice(-2)}</span>
        <div
          className="segment-thumbnails__nav__item__content__title"
        >
          {name}
        </div>
      </div>
    </div>
  </button>
));

export default memo(SegmentThumbnails);
