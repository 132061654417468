import React from 'react';
import ReactDOM from 'react-dom/client';
import './scss/app.scss';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import common_fr from './translations/fr/common.json';
import common_en from './translations/en/common.json';
import App from './App';
import { LiveMessageProvider } from './context/LiveMessageProvider';

i18n.init({
  interpolation: { escapeValue: false },
  lng: 'en', // language to use
  resources: {
    en: {
      common: common_en, // 'common' is our custom namespace
    },
    fr: {
      common: common_fr,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <LiveMessageProvider>
      <I18nextProvider i18n={i18n}>
        <App />
      </I18nextProvider>
    </LiveMessageProvider>
  </React.StrictMode>,
);
