import React from 'react';
import { useTranslation } from 'react-i18next';

function PostalLookupInputField({ tempPostalCode, setTempPostalCode, setInputFieldLabelID }) {
  const { t } = useTranslation('common');
  const handleChange = (input) => {
    const formattedPostalCode = input
      .replace(/(.{3})/, '$1 ')
      .replace('  ', ' ')
      .trim()
      .toUpperCase();

    setTempPostalCode(formattedPostalCode);
  };

  return (
    <>
      <label htmlFor={`postal-lookup-field--${setInputFieldLabelID}`}>{t('app.enter_code_with_format')}</label>
      <input
        id={`postal-lookup-field--${setInputFieldLabelID}`}
        type="text"
        maxLength="7"
        onChange={(event) => handleChange(event.target.value)}
        value={tempPostalCode || ''}
      />
    </>
  );
}

export default PostalLookupInputField;
