import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import MarketingSections from './MarketingSections';

function Marketing() {
  const { t } = useTranslation('common');
  return (
    <section className="marketing" id="how_prizm_helps">
      <div className="marketing__inner">
        <div className="marketing__inner__header">
          <h2 className="title title--secondary">
            <Trans
              t={t}
              i18nKey="app.how_we_help"
              components={[<sup />]}
            />
          </h2>
          <p className="title title--subtitle">{t('app.marketing_subtitle')}</p>
        </div>
        <MarketingSections />
      </div>
    </section>
  );
}

export default Marketing;
