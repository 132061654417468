import { useEffect } from 'react';

const NAVIGATION_KEY_MAP = /** @type {const} */ ({
  ArrowDown: 'ArrowDown',
  ArrowLeft: 'ArrowLeft',
  ArrowRight: 'ArrowRight',
  ArrowUp: 'ArrowUp',
  End: 'End',
  Escape: 'Escape',
  Home: 'Home',
  PageDown: 'PageDown',
  PageUp: 'PageUp',
  Tab: 'Tab',
});

export default function useUserIsTabbing() {
  useEffect(() => {
    /** @param {PointerEvent | KeyboardEvent} event */
    function setInput(event) {
      const { key, type } = event;

      if (type === 'keydown' && NAVIGATION_KEY_MAP[key]) {
        document.body.classList.add('user-is-tabbing');
      }

      if (type === 'pointerdown') {
        document.body.classList.remove('user-is-tabbing');
      }
    }

    window.addEventListener('pointerdown', setInput, true);
    window.addEventListener('keydown', setInput, true);
    return () => {
      window.removeEventListener('pointerdown', setInput, true);
      window.removeEventListener('keydown', setInput, true);
    };
  }, []);
}
