import React, { useState, useRef } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import LanguageSelector from './LanguageSelector';
import { trackEvent } from '../utils';

const track = (event) => {
  trackEvent({
    type: 'event',
    event: 'Interaction',
    event_category: 'Navigation',
    event_type: 'Nav Click',
    event_detail: event.target.innerHTML,
  });
};

function MenuItems({ handleClick, language, desktop }) {
  const { t } = useTranslation('common');
  return (
    <>
      <li className={desktop ? 'util__desktop-only' : 'explore-prizm'}>
        <a href="#explore" onClick={(event) => handleClick(event)}>
          <Trans
            t={t}
            i18nKey="app.explore"
            components={[<sup />]}
          />
        </a>
      </li>
      <li className={desktop ? 'util__desktop-only' : 'how-prizm-helps'}>
        <a href="#how_prizm_helps" onClick={(event) => handleClick(event)}>
          <Trans
            t={t}
            i18nKey="app.how_we_help"
            components={[<sup />]}
          />
        </a>
      </li>
      <li className={desktop ? 'util__desktop-only' : 'environics-analytics'}>
        <a href={t('app.home_link')} target="_self" onClick={(event) => handleClick(event)}>
          {t('app.ea')}
        </a>
      </li>
      <li className={desktop ? 'util__desktop-only language-selector' : 'language-selector'}>
        <LanguageSelector lang={language} />
      </li>
      <li className={desktop ? 'util__desktop-only' : 'contact-us'}>
        <a href={t('app.contact_link')} target="_self" className="primary-cta" onClick={(event) => handleClick(event)}>
          {t('app.contact_us')}
        </a>
      </li>
    </>
  );
}

function Header({ language }) {
  const { t } = useTranslation('common');
  const [menuOpen, setMenuOpen] = useState(false);
  const headerRef = useRef(null);
  const toggleButtonRef = useRef(null);

  const handleClick = (event = null) => {
    if (event) {
      track(event);
    }

    setMenuOpen(false);
  };

  const handleHeaderKeydown = (e) => {
    if (!menuOpen) return;

    if (e.key === 'Tab') {
      const focusableElements = headerRef.current.querySelectorAll(
        '.site-nav__menu-button, .site-mobile-nav li a, [tabindex]:not([tabindex="-1"])',
      );

      const firstElement = focusableElements[0];
      const lastElement = focusableElements[focusableElements.length - 1];

      if (e.shiftKey && document.activeElement === firstElement) {
        lastElement.focus();
        e.preventDefault();
      } else if (!e.shiftKey && document.activeElement === lastElement) {
        e.preventDefault();
        firstElement.focus();
      }
    }

    if (e.key === 'Escape') {
      setMenuOpen(false);
      toggleButtonRef.current?.focus();
    }
  };

  return (
    <header className="site-header" ref={headerRef} onKeyDownCapture={handleHeaderKeydown}>
      <a href="/" className="site-header__logo">
        <img src="/assets/img/prizm_logo.svg" alt={t('app.alt_logo')} />
      </a>
      <nav aria-label="Primary Navigation">
        <button className="site-nav__menu-button util__mobile-only" type="button" aria-label={t('app.menu')} aria-controls="site-mobile-nav-ctrl" aria-expanded={menuOpen} ref={toggleButtonRef} onClick={() => setMenuOpen(!menuOpen)}>
          {!menuOpen ? (
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect y="3" width="20" height="2" fill="black" />
              <rect y="9" width="20" height="2" fill="black" />
              <rect y="15" width="20" height="2" fill="black" />
            </svg>
          ) : (
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="2.22192" y="16.1421" width="20" height="2" transform="rotate(-45 2.22192 16.1421)" fill="#582c83" />
              <rect x="3.63599" y="2" width="20" height="2" transform="rotate(45 3.63599 2)" fill="#582c83" />
            </svg>
          )}
        </button>
        <ul className="site-nav">
          <MenuItems handleClick={handleClick} language={language} desktop />
        </ul>
        <section className="site-mobile-nav" id="site-mobile-nav-ctrl" aria-hidden={menuOpen ? 'false' : 'true'}>
          <ul>
            <MenuItems handleClick={handleClick} language={language} />
          </ul>
        </section>
      </nav>
    </header>
  );
}

export default Header;
