import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Cookies from 'universal-cookie';
import { trackEvent } from '../utils';

const cookies = new Cookies();

function Login({ close }) {
  const { t } = useTranslation('common');
  const [password, setPassword] = useState(null);
  const [loginMessage, setLoginMessage] = useState();
  const [success, setSuccess] = useState(false);

  const handleLogin = () => {
    if (password === process.env.REACT_APP_EMPLOYEE_KEY) {
      const date = new Date();
      // Cookie expires in 1 year
      date.setDate(date.getDate() + 365);
      cookies.set('employee', 'true', { expires: date });
      cookies.remove('dailyLimit');
      setSuccess(true);
      setLoginMessage((t('app.logged_in')));
      trackEvent({
        type: 'event',
        event: 'Interaction',
        event_category: 'Admin',
        event_type: 'Employee Login',
      });
    } else {
      setSuccess(false);
      setLoginMessage(t('app.wrong_password'));
    }
  };

  const keyPress = (event) => {
    if (event.keyCode === 13) {
      handleLogin();
    }
  };

  return (
    <section>
      <div
        className="modal__inner modal__inner--login"
      >
        <h2
          className="title"
        >
          {(t('app.login'))}
        </h2>
        <p>{t('app.employees_only')}</p>
        <label
          htmlFor="login__input"
        >
          {t('app.password')}
        </label>
        <input
          id="login__input"
          type="password"
          autoComplete="current-password"
          onKeyDown={(event) => keyPress(event)}
          onChange={(event) => setPassword(event.target.value)}
        />
        <button
          className="primary-cta"
          onClick={() => handleLogin()}
          type="button"
        >
          {(t('app.login'))}
        </button>
        {loginMessage && (
        // This  aria message can't be fired off with useLiveMessage
        // because it's rendered in a portal. We'll need to rethink this, but
        // for now it works fine.
          <p
            className={success ? 'modal__inner--success' : 'modal__inner--error'}
            role="alert"
          >
            {loginMessage}
          </p>
        )}
        <button
          className="modal__inner__close"
          onClick={() => close('login')}
          type="button"
          aria-label={(t('app.closeUpper'))}
        >
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="2.22192" y="16.1421" width="20" height="2" transform="rotate(-45 2.22192 16.1421)" fill="black" />
            <rect x="3.63599" y="2" width="20" height="2" transform="rotate(45 3.63599 2)" fill="black" />
          </svg>
        </button>
      </div>
    </section>
  );
}

export default Login;
